const __request = require(`../__request/__request_contentType_form`);

// 国际机票后台改签列表
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/inter/buyer/manage/order/findChangeList',
    data:data
  }

  return __request(pParameter)
}

